@import 'Styles/partials/_variables';

.Mechanisms {
    height: auto;
    display: flex;
    align-items: center;
    background: url('../../Assets/images/token-economy.png') no-repeat right -300px top;
    background-size: auto 100%;

    @include media-breakpoint-up(lg) {
        height: 80vh;
    }
}

.Mechanism {
    width: 50%;
}

.swiper {
    :global(.swiper-pagination) {
        position: relative;
        margin-top: 30px;
        justify-content: flex-start;
    }
}