@import 'Styles/partials/_variables';

Header {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;

  @include media-breakpoint-up(md) {
    background-position-x: 50%;
    background-size: initial;
  }
}

.Logo {
  padding-top: 30px;
  padding-left: 30px;
  position: absolute;
  z-index: 2;

  @include media-breakpoint-up(md) {
    padding-top: 40px;
    padding-left: 40px;
  }
}

.LanguageSelector {
  display: flex;
  margin-right: calcrem(16px);
  margin-bottom: calcrem(24px);
  justify-content: flex-end;
  position: absolute;
  right: 10px;
  top: 30px;

  @include media-breakpoint-up(lg) {
    width: auto;
    margin: 9px;
    top: 40px;
    right: 40px;
  }
}

.Container {
  height: 100%;
  text-align: center;
  z-index: 1;

  h2 {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 30px;

    span {
      color: $color-primary;
    }
  }

  p {
    color: #fff;
    margin-bottom: 30px;
  }

  @include media-breakpoint-up(md) {
    margin-top: 50px;

    h2 {
      font-size: 58px;
      line-height: 68px;
      margin-bottom: 30px;
    }
  }
}

.Container__Row {
  height: 100%;
  align-items: center;
}

.Container__content {
  p {
    font-size: 18px;
  }

  @include media-breakpoint-up(md) {
    margin-top: 70px;
    margin-bottom: 60px;
  }
}

.Video__wrapper {
  video {
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    max-width: 100vw;
    max-height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }
}
