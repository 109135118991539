@import "Styles/partials/_variables";

.cta {
  z-index: 1;
  position: relative;

  .container {
    background: url("../../Assets/images/cta_decor.svg") no-repeat left 60px;
  }

  .cta-block {
    @include cta();
    background-image: url("../../Assets/images/cta.svg");

    @media all and (max-width: 992px) {
      margin-left: -17px;
      margin-right: -17px;
    }

    &__content {
      text-align: center;
      max-width: 65%;
      z-index: 1;

      @media all and (max-width: 992px) {
        max-width: 100%;
        padding-left: 32px;
        padding-right: 32px;
      }

      h2 {
        font-size: 32px;
        font-family: $font-primary;
        font-weight: 700;
        color: #000000;
        line-height: 34px;
        margin: 0 0 10px 0;

        @media all and (max-width: 992px) {
          font-size: 30px;
        }
      }

      p {
        font-size: 24px;
        font-family: $font-family-baseary;
        font-weight: 400;
        color: $color-light1;
        line-height: 32px;
        margin: 0 0 18px 0;

        @media all and (max-width: 992px) {
          font-size: 18px;
          line-height: 24px;
        }
      }

      button {
        border: 0;
        display: inline-flex;
        background: $color-navy-light;
        height: 50px;
        width: 210px;
        font-family: $font-family-baseary;
        font-weight: 700;
        font-size: 13px;
        text-transform: uppercase;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        transition: all 250ms ease;

        &:hover {
          text-decoration: none;
          background: #ffffff;
          color: $color-navy-light;
        }
      }
    }
  }
}
