@import 'Styles/partials/_variables';

.Container {
  position: relative;
  margin-top: 0;
  margin-bottom: 100px;
}

.Title {
  text-align: center;
}

.Dot {
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: $color-primary;
}

.Shape {
  position: absolute;
  margin-bottom: 0;
  opacity: 0.3;
}

.Frame {
  position: relative;
  max-width: 900px;
  height: auto;
  aspect-ratio: 16/9;
  margin: 120px auto;
  border: 4px solid $color-primary;

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  @media all and (max-width: 992px) {
    margin: 70px auto;
  }

  .Dot_TL {
    top: -8px;
    left: -8px;
  }

  .Dot_TR {
    top: -8px;
    right: -8px;
  }

  .Dot_BR {
    bottom: -8px;
    right: -8px;
  }

  .Dot_BL {
    bottom: -8px;
    left: -8px;
  }

  .Shape_TL {
    top: -95px;
    left: -62px;
    width: 272px;
    height: 152px;
  }

  .Shape_TM {
    display: none;
    top: -87px;
    width: 228px;
    height: 140px;

    @include media-breakpoint-up(sm) {
      display: block;
      right: 0;
    }

    @include media-breakpoint-up(md) {
      right: 135px;
    }
  }

  .Shape_TR {
    top: 0;
    right: -87px;
    width: 84px;
    height: 136px;
  }

  .Shape_BR {
    bottom: -86px;
    right: 0;
    width: 152px;
    height: 158px;
  }

  .Shape_BL {
    bottom: -112px;
    left: -88px;
    width: 352px;
    height: 249px;
  }
}

.FrameContent {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1;

  .ContentWrapper {
    // max-width: 500px;
    padding: 0 32px;
    text-align: center;

    h4 {
      margin: 0;
      font-size: 24px;
      font-weight: 700;
      line-height: 34px;

      @media all and (max-width: 992px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

.Shape3 {
  top: -50px;
  left: 100px;

  img {
    width: 198px;
  }
}

.Shape4 {
  top: 32px;
  left: 200px;

  img {
    width: 109px;
  }
}

.Shape5 {
  top: 190px;
  right: 220px;

  img {
    width: 40px;
  }
}

.ShapeGroup2 {
  top: 250px;
  right: 140px;

  img {
    width: 175px;
  }
}

.PlayIcon {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  background-color: $color-dark2;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: #fff;
    width: 75px;
    height: 75px;
    margin-left: 10px;
  }
}