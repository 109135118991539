@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/_mixins';

// bootstrap vars
$font-size-base: 16px;
$grid-gutter-width: 40px;

$dropdown-height: 48px;

$font-primary: 'Zona Pro', sans-serif;
$font-family-baseary: 'Work Sans', sans-serif;

$font-family-base: $font-primary;

$color-primary: #d89628;
$color-background: #141414;
$color-light1: #ffffff;
$color-light2: #737373;
$color-light3: #a0a9af;
$color-light4: #d3d8da;
$color-dark1: #000000;
$color-dark2: #121319;
$color-dark3: #402d0c;
$color-error: #ff0011;
$color-navy-light: #23345a;
$color-grey-dark: #121319;
$color-gun-metal: #212227;

@function calcrem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

@mixin cta() {
  width: 1192px;
  height: 344px;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  position: relative;

  > img {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    max-width: 100%;
    height: auto;
  }
}