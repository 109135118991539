@import 'Styles/partials/_variables';

.LanguageSelector {
  @include media-breakpoint-up(lg) {
    min-width: 150px;
  }
}

.LanguageSelector__icon {
  margin-right: 10px;

  svg {
    width: 24px;
    height: 24px;
  }
}

.LanguageSelector__name {
  display: none;

  @include media-breakpoint-up(lg) {
    display: inline-block;
  }
}
