@import 'Styles/partials/_variables';

.Button {
  display: inline-flex;
  width: 100%;
  padding: 0 36px;
  border: 1px solid transparent;
  margin: 0;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  font-size: 13px;
  font-weight: 700;
  line-height: 28px;
  transition: all 250ms ease;
  appearance: none;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    text-decoration: none;
  }

  &:focus,
  &:active {
    outline: 0;
  }
}

.Button_primary {
  color: $color-light1;
  background-color: $color-primary;

  &:not(:disabled):hover {
    color: $color-primary;
    background-color: $color-light1;
  }
}

.Button_secondary {
  color: $color-light1;
  background-color: $color-dark2;

  &:not(:disabled):hover {
    color: $color-dark2;
    background-color: $color-light1;
  }
}

.Button_transparentWithBorder {
  color: $color-light1;
  border-color: $color-primary;

  &:not(:disabled):hover {
    background-color: $color-primary;
  }
}

.Button_medium {
  height: 48px;
}
