@import 'Styles/partials/_variables';

.Platforms {
  text-align: center;
  margin-bottom: 100px;
  margin-top: 50px;

  h2 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 130px;
    margin-top: 150px;
    
    h2 {
      margin-bottom: 100px;
    }
  }

  &__ring {
    position: relative;
    height: 350px;

    img {
      position: absolute;
      height: auto;
      max-width: 300px;
      width: auto;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      opacity: 0;
      transition: opacity 0.5s ease;
    }

    @include media-breakpoint-up(sm) {
      margin-bottom: 50px;
      height: 450px;
      img {
        max-height: 450px;
        width: auto;
        max-width: none;
      }
    }

    @include media-breakpoint-up(md) {
      height: 650px;
      img {
        max-height: 650px;
      }
    }
  }

  &__swiper {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    :global {
      .swiper-container {
        position: initial;
      }

      .swiper-wrapper {
        h4 {
          font-size: 25px;
        }

        p {
          font-size: 13px;
        }

        @include media-breakpoint-up(md) {
          h4 {
            font-size: 32px;
          }

          p {
            font-size: 15px;
          }
        }
      }

      .swiper-pagination {
        display: flex;
        margin: 0 -5px;
        position: absolute;
        bottom: -30px;
        left: 0;
        right: 0;
        align-items: center;
        justify-content: center;
        z-index: 10;

        @include media-breakpoint-up(xl) {
          bottom: 30%;
        }
      }
    }
  }
}
