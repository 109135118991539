@font-face {
  font-family: 'Zona Pro';
  font-style: normal;
  font-weight: 700;
  src: local('Zona Pro Bold'), local('ZonaPro-Bold'), url('../../Assets/fonts/ZonaPro-Bold.woff2') format('woff2'),
    url('../../Assets/fonts/ZonaPro-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Zona Pro';
  font-style: normal;
  font-weight: 400;
  src: local('Zona Pro Regular'), local('ZonaPro-Regular'),
    url('../../Assets/fonts/ZonaPro-Regular.woff2') format('woff2'),
    url('../../Assets/fonts/ZonaPro-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Zona Pro';
  font-style: normal;
  font-weight: 100;
  src: local('Zona Pro ExtraLight'), local('ZonaPro-ExtraLight'),
    url('../../Assets/fonts/ZonaPro-ExtraLight.woff2') format('woff2'),
    url('../../Assets/fonts/ZonaPro-ExtraLight.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Zona Pro';
  font-style: normal;
  font-weight: 600;
  src: local('Zona Pro SemiBold'), local('ZonaPro-SemiBold'),
    url('../../Assets/fonts/ZonaPro-SemiBold.woff2') format('woff2'),
    url('../../Assets/fonts/ZonaPro-SemiBold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Work Sans Regular'), local('WorkSans-Regular'),
    url('../../Assets/fonts/WorkSans-Regular.woff2') format('woff2'),
    url('../../Assets/fonts/WorkSans-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  src: local('Work Sans Medium'), local('WorkSans-Medium'),
    url('../../Assets/fonts/WorkSans-Medium.woff2') format('woff2'),
    url('../../Assets/fonts/WorkSans-Medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Work Sans Bold'), local('WorkSans-Bold'), url('../../Assets/fonts/WorkSans-Bold.woff2') format('woff2'),
    url('../../Assets/fonts/WorkSans-Bold.woff') format('woff');
  font-display: swap;
}
