@import 'Styles/partials/_variables';

html,
body {
  overflow-x: hidden;
}

html {
  min-width: 310px;
}

body {
  -webkit-font-smoothing: antialiased;
  font-family: $font-primary;
  color: $color-light1;
  font-size: 16px;
  line-height: 24px;
  background-color: $color-dark1;
  overflow-x: hidden;
  width: 100%;

  // @media (max-width: 576px){
  //   .card-service {
  //      margin-left: 15px;
  //      margin-right: 15px;
  //      margin-bottom: 25px !important;
  //   }
  // }
}

.container {
  @include media-breakpoint-up(xl) {
    max-width: 1400px;
  }
}

h2 {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;

  @include media-breakpoint-up(lg) {
    font-size: 48px;
    line-height: 56px;
  }
}

h4 {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
}

a {
  &:hover {
    color: inherit;
  }
}

p {
  font-family: $font-family-baseary;
  color: $color-light3;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
}

.grecaptcha-badge {
  display: none !important;
}

[data-aos='omg-font-color-header'] {
  color: $color-light1;
  transition-property: color;

  &.aos-animate {
    color: $color-primary;
  }
}

.opacity-1 {
  opacity: 1 !important;
}
