@import 'Styles/partials/_fonts';
@import 'Styles/partials/_variables';

@import '~aos/src/sass/aos';

@import '~bootstrap/scss/bootstrap-reboot';
@import '~bootstrap/scss/bootstrap-grid';
@import '~bootstrap/scss/utilities/position';
@import '~bootstrap/scss/utilities/sizing';
@import '~bootstrap/scss/utilities/text';
@import '~swiper/swiper';

@import 'Styles/partials/_base';

@import 'Styles/components/_slider-pagination';

.collapsing {
  height: initial;
}
