@import 'Styles/partials/_variables';

.Footer {
  padding-top: 60px;
  // padding-bottom: 130px;
  background-color: #121319;
}
p {
  color: #a0a9af;
}
h6 {
  color: #fff;
  font-weight: bold;
}
@media (min-width: 992px) {
  .Footer {
    padding-top: 60px;
  }
}

.Footer__topWrapper {
  padding-bottom: 0;

  @media (min-width: 992px) {
    padding-bottom: 80px;
  }
}

.Footer__details {
  height: 100%;
  margin-bottom: 40px;
  text-align: center;
}
@media (min-width: 992px) {
  .Footer__details {
    margin-bottom: 0;
    text-align: left;
  }
}
.Footer__details h6 {
  margin-bottom: 5px;
  font-size: 18px;
  line-height: 26px;
}
.Footer__details p {
  margin: 0;
  font-family: $font-primary;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
}

.Footer__logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.Footer__logo__shapeLeft {
  margin-right: 30px;
}

.Footer__logo__shapeRight {
  margin-left: 30px;
  transform: scaleX(-1);
}

.Footer__info {
  height: 100%;
  margin-top: 40px;
  text-align: center;
}
@media (min-width: 992px) {
  .Footer__info {
    margin-top: 0;
    text-align: right;
  }
}
.Footer__info h6 {
  margin-bottom: 10px;
  color: #a0a9af;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
}

.Footer__copyright {
  text-align: center;
}
@media (min-width: 992px) {
  .Footer__copyright {
    text-align: left;
  }
}
.Footer__copyright p {
  margin-bottom: 10px;
  font-family: $font-primary;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
}
.Footer__copyright p:last-of-type {
  margin-bottom: 0;
}

.Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 210px;
  height: 48px;
  font-size: 13px;
  font-weight: 700;
  line-height: 28px;
  box-shadow: 0 0 20px rgba(255, 180, 0, 0);
  border: 1px solid #d89628;
  background-color: #d89628;
  color: #ffffff;
  transition: all 350ms ease;

  &:hover {
    color: #fff;
  }
}

@media (max-width: 992px) {
  .Footer {
    padding-bottom: 60px;
  }
}
