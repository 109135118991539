@import 'Styles/partials/_variables';

.Dropdown {
  position: relative;
}

.Dropdown__header {
  display: flex;
  width: 100%;
  height: calcrem($dropdown-height);
  padding: 0 calcrem(20px);
  border: 0;
  border-radius: calcrem(28px);
  position: relative;
  align-items: center;
  justify-content: flex-start;
  z-index: 1;
  color: #fff;
  background-color: $color-gun-metal;
  outline: 0;
  font-family: $font-family-base;
  font-size: calcrem(12px);
  font-weight: 700;
  transition: background 300ms ease, border-radius 300ms ease;
  cursor: pointer;

  &:active,
  &:focus {
    border: 0;
    outline: 0;
  }

  span {
    overflow: hidden;
    width: 100%;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  figure {
    transition: transform 300ms ease;
    will-change: transform;
  }
}

.Dropdown_isExpanded {
  .Dropdown__header {
    border-radius: calcrem(16px) calcrem(16px) 0 0;

    figure {
      transform: rotate(-180deg);
    }
  }

  .Dropdown__collapse {
    border-bottom-left-radius: calcrem(16px);
    border-bottom-right-radius: calcrem(16px);
  }

  .Dropdown__content {
    border-top-color: #000;
    box-shadow: 0 calcrem(10px) calcrem(30px) 0 rgba(0, 0, 0, 0.14);
  }
}

.Dropdown__collapse {
  width: 100%;
  padding-top: calcrem($dropdown-height);
  border-radius: calcrem(28px);
  margin-bottom: 50px;
  position: absolute;
  top: 0;
  visibility: visible !important;
  background-color: $color-gun-metal;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.14);
  transition: height 300ms ease, border-radius 300ms ease;
  will-change: transform;
}

.Dropdown__content {
  overflow: hidden;
  width: 100%;
  max-height: 50vh;
  border-top: 1px solid transparent;
  box-shadow: 0 0 0 0 transparent;
  transition: border-radius 300ms ease, border-top 300ms ease;

  > * {
    display: flex;
    width: 100%;
    height: 30px;
    padding: 0 calcrem(20px);
    border: 0;
    align-items: center;
    justify-content: flex-start;
    color: #fff;
    background-color: transparent;
    outline: 0;
    font-size: calcrem(12px);
    font-weight: 700;
    transition: color 300ms ease-out;
    cursor: pointer;
    appearance: none;

    &:first-child {
      margin-top: calcrem(10px);
    }

    &:last-child {
      margin-bottom: calcrem(10px);
    }

    &:hover {
      color: $color-primary;
      text-decoration: none;
    }

    &:focus {
      outline: 0;
    }
  }
}
