@import 'Styles/partials/_variables';

.Coupons {
  margin-top: 0;
  margin-bottom: 80px;

  @include media-breakpoint-up(xl) {
    margin-bottom: 270px;
  }

  h3 {
    margin-bottom: 95px;
  }
}

.Coupons__note {
  display: block;
  margin-top: 60px;
  color: $color-light2;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.ShapeGroup1 {
  top: -50px;
  left: 0;

  img {
    width: 175px;
  }
}

.Shape1 {
  bottom: -200px;
  right: 40px;

  img {
    width: 109px;
  }
}

.Centered {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 80px;
  flex-direction: column;
  flex-wrap: wrap;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    gap: 0px;
  }
}

.Title {
  text-align: center;
  margin-bottom: 50px;
  margin-top: -100px;

  @include media-breakpoint-up(lg) {
    margin-top: -200px;
    margin-bottom: 100px;
  }
}