@import 'Styles/partials/_variables';

.Description {
  text-align: center;
  padding-top: 100px;

  h2 {
    margin-bottom: 50px;
  }

  p {
    margin-bottom: 50px;
    font-size: 14px;
  }

  @include media-breakpoint-up(md) {
    padding-top: 150px;

    h2 {
      margin-bottom: 50px;
      font-size: 42px;
    }

    p {
      margin-bottom: 50px;
      font-size: 18px;
    }

    span {
      color: $color-primary;
    }
  }
}

.Description__boxContainerWrapper {
  position: absolute;
  bottom: -720px;
  p {
    color: #fff;
    font-weight: bold;
    margin-bottom: 0px;
    font-size: 15px;

    @include media-breakpoint-up(xl) {
      text-align: right;
      margin-right: 40px;
    }
  }

  @include media-breakpoint-down(lg) {
    bottom: -800px;
  }

  @include media-breakpoint-down(md) {
    bottom: -780px;
    margin: 0;
    left: 0;
    right: 0;
    position: unset;

    p {
      font-size: 13px;
    }
  }
  @include media-breakpoint-down(sm) {
    height: 350px;
    bottom: -780px;

    p {
      font-size: 13px;
    }
  }

  @media (max-width: 390px) {
    bottom: -860px;
  }
}

.Description__box__quotes {
  position: absolute;
  z-index: 2;
  left: 360px;
  top: 25px;
  display: block;

  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.Description__boxContainer {
  position: relative;
  z-index: 1;
  background-color: $color-primary;
  border-radius: 5px;
  padding: 10px;

  @include media-breakpoint-up(md) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &::before,
  &::after {
    @include media-breakpoint-up(md) {
      display: block;
      content: '';
    }
  }

  //&::before {
  //  width: 93px;
  //  height: 23px;
  //  top: -1px;
  //  right: 180px;
  //  background-image: url('../../Assets/images/svg/cta_shape_top.svg');
  //}

  &.Description__boxContainer__pattern_black {
    &::after {
      background-image: url('../../Assets/images/svg/cta_shape_bottom_black.svg');
    }
  }
}

.Description__boxContainer__pattern {
  overflow: hidden;

  svg {
    @include media-breakpoint-up(md) {
      width: 100%;
    }
  }
}

.Description__boxContainer__pattern_top {
  @include media-breakpoint-up(md) {
    padding-bottom: 10px;
  }
}

.Description__boxContainer__pattern_bottom {
  @include media-breakpoint-up(md) {
    padding-top: 10px;
  }
}

.Description__boxContainer__content {
  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
  }

  img {
    display: none;
    max-height: 180px;
    border-radius: 5px;
    position: absolute;
    bottom: -80px;
    left: 50px;

    @include media-breakpoint-up(xl) {
      display: inline-block;
    }
  }

  p {
    margin-bottom: 20px;
    color: $color-dark3;
    font-size: 11px;
    line-height: 20px;
    font-weight: 500;
    text-align: center;

    @include media-breakpoint-up(md) {
      margin-bottom: 30px;
      font-size: 15px;
      line-height: 20px;
      margin-bottom: 20px;
    }

    @include media-breakpoint-up(lg) {
      margin-right: 0px;
    }
  }
}
