@import 'Styles/partials/_variables';

.swiper-pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  &-bullet {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    margin: 0 10px;
    border-radius: 100%;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;

    &:after {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background-color: $color-light1;
    }

    &-active {
      border-color: $color-primary;

      &:after {
        background-color: $color-primary;
      }
    }
  }
}
