@import 'Styles/partials/_variables';

.Coupon {
  position: relative;
  padding: 70px 15px 40px;
  border: 1px solid $color-primary;
  background-color: $color-dark2;
  font-weight: 600;
  text-align: center;
  height: 200px;

  @include media-breakpoint-up(xl) {
    height: 100%;
  }
}

.Coupon__bonus {
  position: absolute;
  top: 0;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  background-color: $color-dark2;
  border: 1px solid $color-primary;
  box-shadow: 0 0 20px rgba(255, 180, 0, 0.5);
  transform: translate(-50%, -50%);
}

.Coupon__bonus__content {
  text-transform: uppercase;
}

.Coupon__bonus__content__label {
  display: block;
  margin-bottom: 5px;
  color: $color-light2;
  font-size: 12px;
  line-height: 18px;
}

.Coupon__button {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%);
  width: 80%;
  cursor: default !important;

  &:hover {
    background-color: $color-primary !important;
    color: #fff !important;
  }
}

.Coupon__bonus__content__value {
  display: block;
  color: #fff;
  font-size: 24px;
  line-height: 24px;
}

.Coupon__tier {
  margin-bottom: 0;
  color: $color-primary;
  font-size: 14px;
  line-height: 21px;
  text-transform: uppercase;
}

.Coupon__amount {
  margin-bottom: 0px;
  color: $color-light1;
  font-size: 16px;
  line-height: 24px;
}

.Coupon__price {
  display: block;
  margin-bottom: 40px;
  color: $color-light3;
  font-size: 12px;
  line-height: 21px;
}

.Coupon__amountReceived {
  margin-bottom: 40px;
  font-size: 12px;
  line-height: 21px;
}

.CouponHeight {
  height: 250px;

  @include media-breakpoint-up(md) {
    height: 350px;
  }

  @include media-breakpoint-up(xl) {
    height: 100%;
  }
}